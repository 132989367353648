<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12">
        <b-card>
          <b-row>
            <b-col>
              <h4>PPE Request Form Edit</h4>
            </b-col>
            <b-col class="text-right">
              <b-button squared size="sm" @click="addMoreModalOpen()" variant="primary" class="mr-2">Add Item</b-button>
              <b-button variant="primary" size="sm" squared @click="doUpdatePpeRequest()">
                <b-spinner small v-if="state === 'loading'"></b-spinner> Save</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                striped
                hover
                :items="tableData.dataSource"
                :fields="tableData.tableColumns"
                z
                :busy="tableData.isLoading"
                sort-icon-left>
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
                  </div>
                </template>

                <template #cell(perishable)="row">
                  <b-form-checkbox
                    v-model="row.item.perishable"
                    @change="requiredFieldMarked(row.item.perishable)"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Expire
                  </b-form-checkbox>
                </template>

                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="removeItem(row.item)" variant="red" size="sm"> Remove </b-button>
                  </b-row>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal id="add-more-modal" hide-footer no-header @close="addMoreModalClose()">
      <b-row>
        <b-col cols="12">
          <label>Description</label>
          <b-form-input v-model="addMore.description"></b-form-input>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="text-right">
          <b-button variant="primary" size="sm" squared @click="doAddMore()">Save</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  data: () => ({
    state: 'initiliazing',
    addMore: {
      description: null,
      perishable: false,
    },
    tableData: {
      totalRecords: 10,
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Description',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Expire or Perishable?',
          key: 'perishable',
          sortable: false,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: '',
        },
      ],
    },
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'PPE Request',
      },
      {
        text: 'Edit Form',
        active: true,
      },
    ]);
    this.loadData();
  },
  methods: {
    ...mapActions(['getAvailableItems', 'updateAvailableItems']),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    ...mapActions('notificationService', ['addErrorMessage', 'clearMessage']),
    loadData() {
      this.getAvailableItems()
        .then((res) => {
          this.tableData.dataSource = res.data.result;
          this.tableData.isLoading = false;
          this.state = 'show';
        })
        .catch(() => {
          this.state = 'show';
          this.tableData.isLoading = false;
        });
    },
    removeItem(rowItem) {
      this.tableData.isLoading = true;
      let itemIndex = this.tableData.dataSource.findIndex((element) => element === rowItem);
      this.tableData.dataSource.splice(itemIndex, 1);
      this.tableData.isLoading = false;
    },

    doUpdatePpeRequest() {
      this.state = 'loading';
      this.tableData.isLoading = true;
      this.$store.commit('setSearchUpdateAvailableItemsRequest', this.tableData.dataSource);

      this.updateAvailableItems()
        .then(() => {
          this.loadData();
        })
        .catch(() => {
          this.tableData.isLoading = false;
        });
    },
    doAddMore() {
      this.tableData.isLoading = true;
      this.tableData.dataSource.push(this.addMore);
      this.tableData.isLoading = false;
      this.addMoreModalClose();
      this.addMore = {
        description: null,
        perishable: false,
      };
    },
    addMoreModalOpen() {
      this.$root.$emit('bv::show::modal', 'add-more-modal', '#btnShow');
    },
    addMoreModalClose() {
      this.$root.$emit('bv::hide::modal', 'add-more-modal', '#btnShow');
    },
  },
  computed: {},
};
</script>
